import {
	Comment,
	CustomPoll,
	CustomPollOption,
	FaqItem,
	FaqItemMultiLang,
	LocalizedString,
	News,
	PartnerBlock,
	Post,
	Sport,
	SportId,
	StatPlayer,
	StatRankingTeamStatItem as StatRankingTeamStatItemV2,
	StatTeam,
	StatTeamStandingLine,
	Tag,
	TagPersonExtra,
	TagPollOption,
	TagTeamExtra,
	TagTournamentExtra,
	User,
} from '@ui-kit/main-api-types';
import {
	INT_LANGS,
	INT_DEFAULT_AUTHOR,
} from '@ui-kit/main-frontend-ui-kit/dist/src/utils/constants';
import {
	LANG as STAT_LANG,
	TeamMemberRole,
} from '@ui-kit/main-frontend-ui-kit/dist/src/utils/types';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslations } from 'next-intl';

import {
	BodyImage,
	StatTeam as IntStatTeam,
	StatMatch,
	StatRankingTeamStatItem,
	StatTournament,
	TagNewFull,
	TagTeamExtraFull,
	TagTeamType,
} from '@generated-graphql';
import { SPORT_NEWS_PAGE_URLS, TRIBUNA_COM_URL } from '@utils/constants';
import {
	addIfArr,
	addIfObj,
	commentContentInfo,
	getFullName,
	getPersonRole,
	getSportPath,
	getTagDirectoryByType,
	preventXss,
} from '@utils/helpers';

import { BETTING_SPORT_URLS } from './bookie';
import { Gender, LANG, TagTeamDirectories, TeamType } from './types';

export interface BreadCrumbsJsonLd {
	'@context': 'https://schema.org';
	'@type': 'BreadcrumbList';
	itemListElement: Array<{
		'@type': 'ListItem';
		position: number;
		name: string;
		item: string;
	}>;
}

export interface BreadCrumbsJsonLdItem {
	name: string;
	item?: string;
}

export const useBreadCrumbsJsonLdSchema = (
	items: BreadCrumbsJsonLdItem[],
): BreadCrumbsJsonLd => {
	return {
		'@context': 'https://schema.org',
		'@type': 'BreadcrumbList',
		itemListElement: items?.map(({ name, item }, index) => ({
			'@type': 'ListItem',
			position: index + 1,
			name,
			item,
		})),
	};
};

export const createBreadcrumbsJsonLd = (
	breadcrumbs: { name: string; link?: string }[],
) =>
	useBreadCrumbsJsonLdSchema(
		breadcrumbs?.map(({ name, link }, i, arr) => ({
			name: preventXss(name),
			...(i !== arr?.length - 1 &&
				link && {
					item: `https://tribuna.com${link}`,
				}),
		})),
	);

export interface IGetMatchCenterBreadcrumbsJson {
	locale: LANG;
	isFavorite?: boolean;
	isLive?: boolean;
	isDate?: boolean;
	date?: Dayjs;
	t: any;
}
export const getMatchCenterBreadcrumbsJson = ({
	locale,
	isFavorite,
	isLive,
	isDate,
	date,
	t,
}: IGetMatchCenterBreadcrumbsJson): BreadCrumbsJsonLdItem[] => {
	const breadcrumbs: { name: string; item?: string }[] = [
		{
			item: `${TRIBUNA_COM_URL}${locale}/`,
			name: 'Tribuna',
		},
		{
			item:
				isLive || isDate || isFavorite
					? `${TRIBUNA_COM_URL}${locale}/match/`
					: '',
			name: t('match-center'),
		},
		...addIfArr(isLive, [
			{
				name: t('match-center-live'),
			},
		]),
		...addIfArr(isFavorite, [
			{
				item: `${TRIBUNA_COM_URL}${locale}/match/favorites/`,
				name: t('match-center-favorite-h1'),
			},
		]),
		...addIfArr(!isLive && isDate, [
			{
				name: date.format('MMMM DD, YYYY'),
			},
		]),
	];

	delete breadcrumbs?.[breadcrumbs?.length - 1]?.item;

	return breadcrumbs;
};

interface IMatchArticle {
	locale: string;
	translationAdapter: any;
	tournament: StatTournament;
	match: StatMatch;
	isItMainMatchPage?: boolean;
	subpageName?: string;
	subpageUrl?: string;
}

export const getMatchBreadcrumbsItems = ({
	locale,
	translationAdapter,
	tournament,
	match,
	isItMainMatchPage = false,
	subpageName,
	subpageUrl,
}: IMatchArticle): BreadCrumbsJsonLdItem[] => {
	const breadcrumbs: BreadCrumbsJsonLdItem[] = [
		{
			item: `https://tribuna.com/${locale}/`,
			name: 'Tribuna',
		},
		{
			item: `https://tribuna.com/${locale}/match/`,
			name: translationAdapter('translations.live'),
		},
		{
			item: `https://tribuna.com/${locale}/league/${tournament?.tag?.hru}/`,
			name: tournament?.name,
		},
		{
			item: `https://tribuna.com/${locale}/match/${match?.hru}/`,
			name: `${match?.home?.team?.name} vs ${match?.away?.team?.name}`,
		},
	];

	if (isItMainMatchPage) {
		return breadcrumbs;
	}

	return [
		...breadcrumbs,
		{
			item: `https://tribuna.com/${locale}/match/${match?.hru}/${subpageUrl}/`,
			name: subpageName,
		},
	];
};
interface TagBreadCrumbsItemsProps {
	sport?: Sport;
	tagLink?: string;
	tagName?: string;
	tagTypeName?: string;
	tagTypePath?: string;
	locale?: string;
	tagPageName?: string;
	tagPagePath?: string;
	tagPageParamsName?: string;
	tagPageParamsPath?: string;
	tagPageParamsAdditionalPath?: string;
	tagPageSecondParamsName?: string;
	tagPageSecondParamsPath?: string;
	tagPageThirdParamsName?: string;
	tagPageThirdParamsPath?: string;
}

export const getTagBreadCrumbsItems = ({
	sport,
	tagLink,
	tagName,
	tagTypeName,
	tagTypePath,
	locale,
	tagPageName,
	tagPagePath,
	tagPageParamsName,
	tagPageParamsPath,
	tagPageParamsAdditionalPath = null,
	tagPageSecondParamsName,
	tagPageSecondParamsPath,
	tagPageThirdParamsName,
	tagPageThirdParamsPath,
}: TagBreadCrumbsItemsProps): BreadCrumbsJsonLdItem[] => {
	const breadcrumbs: BreadCrumbsJsonLdItem[] = [
		{
			item: `https://tribuna.com/${locale}/`,
			name: 'Tribuna',
		},
		...addIfArr(!!sport && sport.id !== SportId.Football, [
			{
				name: sport?.name?.[locale],
				item: `https://tribuna.com/${locale}/${sport?.path}/`,
			},
		]),
		{
			name: tagTypeName,
			item: `https://tribuna.com/${locale}/${tagTypePath}/`,
		},
		{
			name: tagName,
			item: `https://tribuna.com/${locale}/${tagTypePath}/${tagLink}/`,
		},
	];

	if (tagPageName) {
		breadcrumbs.push({
			name: tagPageName,
			item: `https://tribuna.com/${locale}/${tagTypePath}/${tagLink}/${tagPagePath}/`,
		});
	}
	if (tagPageParamsName) {
		breadcrumbs.push({
			name: tagPageParamsName,
			...(tagPageParamsPath && {
				item: `https://tribuna.com/${locale}/${tagTypePath}/${tagLink}/${tagPagePath}/${tagPageParamsPath}/${tagPageParamsAdditionalPath ? `${tagPageParamsAdditionalPath}/` : ''}`,
			}),
		});
	}
	if (tagPageSecondParamsName) {
		breadcrumbs.push({
			name: tagPageSecondParamsName,
			...(tagPageSecondParamsPath && {
				item: `https://tribuna.com/${locale}/${tagTypePath}/${tagLink}/${tagPagePath}/${tagPageParamsPath}/${tagPageSecondParamsPath}/`,
			}),
		});
	}
	if (tagPageThirdParamsName) {
		breadcrumbs.push({
			name: tagPageThirdParamsName,
			...(tagPageThirdParamsPath && {
				item: `https://tribuna.com/${locale}/${tagTypePath}/${tagLink}/${tagPagePath}/${tagPageParamsPath}/${tagPageSecondParamsPath}/${tagPageThirdParamsPath}/`,
			}),
		});
	}

	delete breadcrumbs?.[breadcrumbs?.length - 1]?.item;

	return breadcrumbs;
};

interface ClubBreadCrumbsItemsProps {
	t: any;
	tag: TagNewFull | Tag;
	sport?: Sport;
	locale?: string;
	tagPageName?: string;
	tagPagePath?: string;
	tagPageParamsName?: string;
	tagPageParamsPath?: string;
	tagPageSecondParamsName?: string;
	tagPageSecondParamsPath?: string;
	tagPageThirdParamsName?: string;
	tagPageThirdParamsPath?: string;
}

export const getClubBreadCrumbsItems = ({
	t,
	tag,
	sport,
	locale,
	tagPageName,
	tagPagePath,
	tagPageParamsName,
	tagPageParamsPath,
	tagPageSecondParamsName,
	tagPageSecondParamsPath,
	tagPageThirdParamsName,
	tagPageThirdParamsPath,
}: ClubBreadCrumbsItemsProps): BreadCrumbsJsonLdItem[] => {
	const tagLink = tag?.hru;
	const tagName = tag?.title?.[locale.toUpperCase()] || tag?.statObject?.name;
	const teamType = (tag?.extra as TagTeamExtraFull)?.type;
	const tagTypePath =
		teamType === TagTeamType.National
			? TagTeamDirectories.Team
			: TagTeamDirectories.Clubs;
	const tagTypeName =
		teamType === TagTeamType.National
			? t('translations.teams')
			: t('translations.clubs');
	const statObject = tag?.statObject as IntStatTeam;
	const leagueName =
		statObject?.homeTournament?.tag?.title?.[locale] ||
		statObject?.homeTournament?.name;
	const leagueHru = statObject?.homeTournament?.tag?.hru;

	const breadcrumbs: BreadCrumbsJsonLdItem[] = [
		{
			item: `https://tribuna.com/${locale}/`,
			name: 'Tribuna',
		},
		...addIfArr(!!sport && sport.id !== SportId.Football, [
			{
				name: sport?.name?.[locale],
				item: `https://tribuna.com/${locale}/${sport?.path}/`,
			},
		]),
		{
			name: leagueName || tagTypeName,
			item: `https://tribuna.com/${locale}/${
				leagueHru ? `league/${leagueHru}` : tagTypePath
			}/`,
		},
		{
			name: tagName,
			item: `https://tribuna.com/${locale}/${tagTypePath}/${tagLink}/`,
		},
	];

	if (tagPageName) {
		breadcrumbs.push({
			name: tagPageName,
			item: `https://tribuna.com/${locale}/${tagTypePath}/${tagLink}/${tagPagePath}/`,
		});
	}
	if (tagPageParamsName) {
		breadcrumbs.push({
			name: tagPageParamsName,
			...(tagPageParamsPath && {
				item: `https://tribuna.com/${locale}/${tagTypePath}/${tagLink}/${tagPagePath}/${tagPageParamsPath}/`,
			}),
		});
	}
	if (tagPageSecondParamsName) {
		breadcrumbs.push({
			name: tagPageSecondParamsName,
			...(tagPageSecondParamsPath && {
				item: `https://tribuna.com/${locale}/${tagTypePath}/${tagLink}/${tagPagePath}/${tagPageParamsPath}/${tagPageSecondParamsPath}/`,
			}),
		});
	}
	if (tagPageThirdParamsName) {
		breadcrumbs.push({
			name: tagPageThirdParamsName,
			...(tagPageThirdParamsPath && {
				item: `https://tribuna.com/${locale}/${tagTypePath}/${tagLink}/${tagPagePath}/${tagPageParamsPath}/${tagPageSecondParamsPath}/${tagPageThirdParamsPath}/`,
			}),
		});
	}

	delete breadcrumbs?.[breadcrumbs?.length - 1]?.item;

	return breadcrumbs;
};

interface PersonBreadCrumbsItems {
	t: any;
	tag: Tag;
	sport?: Sport;
	locale: string;
	personHru: string;
	personName: string;
	tabName?: string;
	tabType?: string;
	currentPage?: number;
	chosenSeason?: string;
	chosenTournament?: string;
	chosenTournamentName?: string;
}

export const getPersonBreadCrumbsItems = ({
	t,
	tag,
	sport,
	locale,
	tabName,
	tabType,
	personHru,
	personName,
	currentPage,
	chosenSeason,
	chosenTournament,
	chosenTournamentName,
}: PersonBreadCrumbsItems) => {
	const statObject = tag?.statObject as StatPlayer;
	const clubName = statObject?.currentClub?.name;
	const clubHru = statObject?.currentClub?.tag?.hru;
	const team =
		statObject?.careersGroup?.national?.find(({ endDate }) => !endDate)?.team ||
		statObject?.currentNational;
	let playerPositionName = '';

	if (
		statObject?.positions?.[0]?.localizedPosition &&
		!(
			[TeamMemberRole.Coach, TeamMemberRole.AssistantCoach] as string[]
		).includes(statObject?.type)
	) {
		playerPositionName = statObject?.positions?.[0]?.localizedPosition;
	} else if (statObject?.fieldPositionName) {
		playerPositionName = statObject?.fieldPositionName;
	} else {
		playerPositionName = getPersonRole(tag, locale, t);
	}
	const breadcrumbs: BreadCrumbsJsonLdItem[] = [
		{
			item: `https://tribuna.com/${locale}/`,
			name: 'Tribuna',
		},
	];

	if (clubName || team) {
		breadcrumbs.push({
			name: clubName || team?.name,
			item: clubHru
				? `https://tribuna.com/${locale}/clubs/${clubHru}/`
				: team?.url,
		});
	}

	breadcrumbs.push(
		...addIfArr(!!sport && sport.id !== SportId.Football, [
			{
				name: sport?.name?.[locale],
				item: `https://tribuna.com/${locale}/${sport?.path}/`,
			},
		]),
		{
			name: playerPositionName,
			item: `https://tribuna.com/${locale}/persons/`,
		},
		{
			name: personName,
			item: `https://tribuna.com/${locale}/persons/${personHru}/`,
		},
	);

	if (tabName) {
		breadcrumbs.push({
			name: tabName,
			item: `https://tribuna.com/${locale}/persons/${personHru}/${tabType}/`,
		});
	}

	if (currentPage > 1) {
		breadcrumbs.push({
			name: `${t('translations.page')} ${currentPage}`,
			item: `https://tribuna.com/${locale}/persons/${personHru}/${tabType}/page${currentPage}/`,
		});
	}
	if (chosenSeason) {
		breadcrumbs.push({
			name: chosenSeason,
			item: `https://tribuna.com/${locale}/persons/${personHru}/${tabType}/${chosenSeason}/`,
		});
	}

	if (chosenTournament) {
		breadcrumbs.push({
			name: chosenTournamentName,
			item: `https://tribuna.com/${locale}/persons/${personHru}/${tabType}/${chosenSeason}/${chosenTournament}/`,
		});
	}

	delete breadcrumbs?.[breadcrumbs?.length - 1]?.item;

	return breadcrumbs;
};

interface IGetTagsListBreadCrumbsItems {
	translationAdapter: any;
	locale: string;
	tagPageName?: string;
	tagPageUrl?: string;
	sportName?: string;
	sportUrl?: string;
	pageNumber?: number;
}

export const getTagsListBreadCrumbsItems = ({
	translationAdapter,
	locale,
	tagPageName,
	tagPageUrl,
	sportName,
	sportUrl,
	pageNumber,
}: IGetTagsListBreadCrumbsItems): BreadCrumbsJsonLdItem[] => {
	const breadcrumbs: BreadCrumbsJsonLdItem[] = [
		{
			item: `${TRIBUNA_COM_URL}${locale}/`,
			name: 'Tribuna',
		},
		...addIfArr(!!sportName, [
			{
				name: sportName,
				item: `${TRIBUNA_COM_URL}${locale}/${sportUrl}/`,
			},
		]),
		...addIfArr(!!tagPageName, [
			{
				name: tagPageName,
				item: `${TRIBUNA_COM_URL}${locale}/${
					sportUrl ? `${sportUrl}/` : ''
				}${tagPageUrl}/`,
			},
		]),
		...addIfArr(!!pageNumber, [
			{
				name: `${translationAdapter('translations.page')} ${pageNumber}`,
				item: `${TRIBUNA_COM_URL}${locale}/${sportUrl ? `${sportUrl}/` : ''}${
					tagPageUrl ? `${tagPageUrl}/` : ''
				}`,
			},
		]),
	];

	delete breadcrumbs?.[breadcrumbs?.length - 1]?.item;

	return breadcrumbs;
};
interface INewsArticleJsonLd {
	news: News;
	locale: string;
}

export const getNewsArticleJsonLd = ({ news, locale }: INewsArticleJsonLd) => {
	const {
		id: newsId,
		hru: newsHru,
		commentsCount,
		author,
		title,
		publicationTime,
		modifiedTime,
		seoDescription,
		mainPhoto,
		structuredBody,
		shareInfo,
		tags,
		mainSport,
	} = news;
	const sportName = mainSport?.name?.[locale];
	const publishedDate = dayjs(publicationTime * 1000).format();
	const modifiedDate = dayjs(modifiedTime * 1000).format();
	const newsTitle = title?.[locale];
	const authorName = author?.user?.name;
	const authorId = author?.user?.id;
	const newsURL =
		mainSport?.id === SportId.Football
			? `${TRIBUNA_COM_URL}${locale}/news/${newsHru || newsId}/`
			: `${TRIBUNA_COM_URL}${locale}/${
					mainSport?.id === SportId.Bet
						? BETTING_SPORT_URLS?.[locale]
						: mainSport?.path
				}/${SPORT_NEWS_PAGE_URLS?.[locale]}/${newsHru || newsId}/`;
	const authorURL = authorId
		? `${TRIBUNA_COM_URL}${locale}/profile/${authorId}/`
		: TRIBUNA_COM_URL;

	const newsTags = tags?.map(tag => tag?.title?.[locale]).join(', ');
	const keywords = `${newsTitle}, ${newsTags}, ${sportName}`;
	const mainPhotoUrl = mainPhoto?.url || shareInfo?.picture?.url;

	const mainImage = {
		'@type': 'ImageObject',
		representativeOfPage: 'true',
		url: mainPhotoUrl,
		width: mainPhoto?.meta?.width || shareInfo?.picture?.meta?.width,
		height: mainPhoto?.meta?.height || shareInfo?.picture?.meta?.height,
	};

	const images = structuredBody?.[locale]?.reduce((acc, item) => {
		if (item?.type === 'image') {
			const itemValue = item?.value as BodyImage;
			acc.push({
				'@type': 'ImageObject',
				representativeOfPage: 'true',
				url: itemValue?.source,
				width: itemValue?.width,
				height: itemValue?.height,
			});
		}
		return acc;
	}, []);

	const mainSportThing = {
		name: sportName,
		'@type': 'Thing',
		url:
			mainSport?.id === SportId.Football
				? `${TRIBUNA_COM_URL}${locale}/`
				: `${TRIBUNA_COM_URL}${locale}/${
						mainSport?.id === SportId.Bet
							? BETTING_SPORT_URLS?.[locale]
							: mainSport?.path
					}/`,
	};

	const mentions = tags?.map(tag => {
		const tagName = tag?.title?.[locale];

		const directory = getTagDirectoryByType(
			tag?.type,
			(tag?.extra as TagTeamExtra)?.type === TagTeamType.National,
		);

		const tagUrl = `${TRIBUNA_COM_URL}${locale}/${
			directory ? `${directory}/` : ''
		}${tag?.hru || tag?.id}/`;

		return {
			name: tagName,
			'@type': 'Thing',
			url: tagUrl,
		};
	});

	return {
		'@context': 'https://schema.org',
		'@type': 'NewsArticle',
		name: preventXss(newsTitle),
		url: newsURL,
		articleSection: sportName,
		commentCount: `${commentsCount}`,
		mainEntityOfPage: {
			'@type': 'WebPage',
			'@id': newsURL,
		},
		headline: preventXss(newsTitle),
		image: [mainImage, ...images],
		dateCreated: publishedDate, // for now we don't have such field in query
		datePublished: publishedDate,
		dateModified: modifiedDate,
		thumbnailUrl: mainPhotoUrl,
		keywords,
		author: {
			'@type': 'Person',
			name: preventXss(authorName),
			url: authorURL,
		},
		publisher: {
			'@type': 'Organization',
			name: 'Tribuna.com',
			logo: {
				'@type': 'ImageObject',
				url: 'https://static.tribuna.com/public/assets/approved-logos/championsleague/apple-icon-180x180.png',
				width: 512,
				height: 512,
			},
		},
		description: preventXss(seoDescription?.[locale]),
		inLanguage: locale.toUpperCase(),
		mentions,
		about: [...mentions, mainSportThing],
	};
};

interface IPersonJsonLd {
	tag: Tag;
	locale: string;
	title: string | any;
	sportName: string;
}

export const getPersonJsonLd = ({
	tag,
	locale,
	title,
	sportName,
}: IPersonJsonLd) => {
	const player = tag?.statObject as StatPlayer;
	const tagExtra = tag?.extra as TagPersonExtra;
	const tagName = getFullName(tag, locale);
	const athlete = player?.type;
	const height = player?.height || tagExtra?.height;
	const weight = player?.weight || tagExtra?.weight;
	const tagLogo = tag?.logo || player?.avatar?.main;
	const gender = player?.gender || Gender[tagExtra?.sex];
	const sportPath = getSportPath({ tag, locale });

	const birthDate = player?.dateOfBirth || tagExtra?.dateBirth;
	const countryOfBirth = player?.countryOfBirth?.[0]?.name;
	const cityOfBirth = player?.placeOfBirth;
	const nationality =
		player?.nationality?.length === 1
			? player?.nationality?.[0]?.name
			: player?.nationality?.map(el => el?.name);

	const currentClub = player?.currentClub;
	const currentClubTitle = currentClub?.name;
	const clubLink = currentClub?.tag?.hru;
	const teamTypeLink =
		currentClub?.type === TeamType.NATIONAL
			? TagTeamDirectories.Team
			: TagTeamDirectories.Clubs;
	const tournaments = currentClub?.currentTournaments;

	const alternateTagNames = Array.from(new Set(Object.values(tag?.title)));

	const synonyms =
		tag?.seo?.synonyms?.[locale.toLowerCase()]?.split(/[,;] | \s/);
	const alternateName = [
		...alternateTagNames,
		tag.subtitle?.[locale.toLowerCase()],
		...addIfArr(!!synonyms, synonyms),
	];

	return {
		'@context': 'https://schema.org',
		'@type': 'Person',
		name: tagName,
		gender: `https://schema.org/${gender}`,
		alternateName: alternateName?.filter(Boolean),
		description: title,
		...addIfObj(!!currentClubTitle, { worksFor: currentClubTitle }),
		...addIfObj(!!height, { height: `${height} cm` }),
		...addIfObj(!!weight, { weight: `${weight} kg` }),
		...addIfObj(!!birthDate, { birthDate }),
		...addIfObj(!!tagLogo, { image: tagLogo }),
		...addIfObj(!!nationality, { nationality }),
		...addIfObj(!!(countryOfBirth || cityOfBirth), {
			birthPlace: {
				'@type': 'Place',
				address: {
					'@type': 'PostalAddress',
					addressLocality: cityOfBirth,
					addressCountry: countryOfBirth,
				},
			},
		}),
		...addIfObj(!!currentClub, {
			memberOf: [
				{
					'@type': 'SportsTeam',
					name: currentClubTitle,
					url: `${TRIBUNA_COM_URL}${locale}${sportPath}/${teamTypeLink}/${clubLink}/`,
					athlete,
				},
				...addIfArr(!!tournaments?.length, [
					{
						'@type': 'SportsOrganization',
						sport: sportName,
						memberOf: tournaments?.map(tour => ({
							'@type': 'Organization',
							name: tour?.name,
							url: `${TRIBUNA_COM_URL}${locale}${sportPath}/league/${tour?.tag?.hru}/`,
						})),
					},
				]),
			],
		}),
	};
};

interface ITournamentJsonLd {
	tag: Tag;
	teams?:
		| StatRankingTeamStatItem[]
		| StatRankingTeamStatItemV2[]
		| StatTeamStandingLine[];
	participants?: Array<IntStatTeam | StatTeam>; // teams but in another type
	sportName: string;
	url: string;
	lang: string;
	t: any;
}

export const getTournamentJsonLd = ({
	tag,
	teams,
	participants,
	sportName,
	url,
	lang,
	t,
}: ITournamentJsonLd) => {
	const statObj = tag?.statObject as unknown as StatTournament;
	const name =
		statObj?.name ||
		tag?.seo?.name?.[lang] ||
		tag?.seo?.name?.en ||
		tag?.title?.[lang] ||
		tag?.title?.en;

	const logo = tag?.logo || statObj?.logo?.main;
	const seoTitle = tag?.seo?.title?.[lang];
	const defaultTitle = t('league.league-page-title', { name });
	const alternateTagNames = Array.from(new Set(Object?.values(tag?.title)));

	const alternateName = [name, ...alternateTagNames].filter(
		el => el && el !== 'LocalizedStringValue',
	);

	const tagExtra = tag?.extra as TagTournamentExtra;
	const dateFoundation = tagExtra?.dateFoundation;
	const foundingLocation = statObj?.country?.name || tagExtra?.country?.[0];
	const sportPath = getSportPath({ tag, locale: lang });

	return {
		'@context': 'http://schema.org',
		'@type': 'SportsOrganization',
		name: name,
		url,
		...addIfObj(!!logo, {
			logo: {
				'@type': 'ImageObject',
				url: logo,
			},
		}),
		...addIfObj(!!logo, { image: logo }),
		...addIfObj(!!alternateName.length, { alternateName }),
		sport: sportName,
		description: seoTitle || defaultTitle,
		...addIfObj(!!dateFoundation, { foundingDate: dateFoundation }),
		...addIfObj(!!foundingLocation, {
			foundingLocation: {
				'@type': 'Country',
				name: foundingLocation,
			},
		}),
		...addIfObj(!!teams?.length, {
			member: teams?.map(item => ({
				'@type': 'SportsTeam',
				name: item?.team?.name,
				url: `https://tribuna.com/${lang}${sportPath}/clubs/${item?.team?.tag?.hru}/`,
			})),
		}),
		...addIfObj(!!participants?.length, {
			member: participants?.map(item => ({
				'@type': 'SportsTeam',
				name: item?.name,
				url: `https://tribuna.com/${lang}${sportPath}/clubs/${item?.tag?.hru}/`,
			})),
		}),
	};
};

interface GetFaqJsonLdProps {
	faqItems: FaqItemMultiLang[] | FaqItem[];
	locale?: LANG | STAT_LANG;
}

export const getFaqJsonLd = ({ faqItems, locale }: GetFaqJsonLdProps) => {
	return {
		'@context': 'https://schema.org',
		'@type': 'FAQPage',
		mainEntity: faqItems?.map(item => {
			const structuredBody = locale
				? item?.structuredBody?.[locale]
				: item?.structuredBody;
			const text = structuredBody
				?.map(({ value, type }) => {
					if (type === 'list') {
						return value?.items?.map(({ elements }) =>
							elements
								?.map(el => {
									return el?.type === 'link'
										? `<a href="${el?.value?.href}">${el?.value?.text}</a>`
										: ` ${el?.value?.text?.replace(/,(?=$)/, '')}`;
								})
								?.join(''),
						);
					}
					return value?.elements
						?.map(el =>
							el?.type === 'link'
								? `<a href="${el?.value?.href}">${el?.value?.text}</a>`
								: el?.value?.text,
						)
						?.join('');
				})
				.join('');

			return {
				'@type': 'Question',
				name: locale ? item?.title?.[locale] : item?.title,
				acceptedAnswer: {
					'@type': 'Answer',
					text: text?.trim(),
				},
			};
		}),
	};
};

interface IGetProfileJsonLd {
	user: User;
	locale?: string;
	locales?: string[];
	comments?: Comment[];
	totalPublishedPostsCount: number;
}

export const getProfileJsonLd = ({
	user,
	locale,
	locales,
	comments,
	totalPublishedPostsCount,
}: IGetProfileJsonLd) => {
	const t = useTranslations('profile');
	const dateCreated = dayjs(+user?.createTime * 1000).toISOString();
	const isCommentsPage = !!comments?.length;
	const userBio = `${preventXss(user?.bio)}. `;
	const pageDescription = isCommentsPage
		? t('profile-comments-description', { name: user?.name || '' })
		: t('profile-description', { name: user?.name || '' });
	const description = `${user?.bio ? userBio : ''}${pageDescription}`;
	return {
		'@context': 'https://schema.org',
		'@type': 'ProfilePage',
		dateCreated,
		dateModified: dateCreated,
		mainEntity: {
			'@type': 'Person',
			name: preventXss(user?.name),
			identifier: user?.id,
			...addIfObj(!!totalPublishedPostsCount, {
				agentInteractionStatistic: {
					'@type': 'InteractionCounter',
					interactionType: 'https://schema.org/WriteAction',
					userInteractionCount: totalPublishedPostsCount,
				},
			}),
			description: preventXss(description),
			image: user?.avatar?.url,
		},
		...addIfObj(!!comments?.length, {
			comment: comments?.map(comment => {
				const content = comment?.parentObject;
				const contentAuthor =
					content?.displayAuthor?.[0]?.user ||
					content?.author?.[0]?.user ||
					content?.['newsAuthor']?.user;
				const { contentTitle, contentLink } = commentContentInfo({
					locale,
					locales,
					content,
				});

				return {
					'@type': 'Comment',
					text: preventXss(comment?.text),
					dateCreated: dayjs(+comment?.created * 1000).toISOString(),
					...addIfObj(!!content?.id, {
						about: {
							'@type': 'DiscussionForumPosting',
							url: `https://tribuna.com${contentLink}`,
							headline: preventXss(contentTitle),
							datePublished:
								!!content?.publicationTime &&
								dayjs(+content?.publicationTime * 1000).toISOString(),
							author: {
								'@type': 'Person',
								name: contentAuthor
									? preventXss(contentAuthor?.name)
									: INT_DEFAULT_AUTHOR.name,
								url: `https://tribuna.com/${locale}/profile/${
									contentAuthor ? contentAuthor?.id : INT_DEFAULT_AUTHOR.id
								}/`,
							},
						},
					}),
				};
			}),
		}),
	};
};

interface IGetProfileNewsJsonLd {
	title: string;
	description: string;
	news: News[];
}

export const getProfileNewsJsonLd = ({
	title,
	description,
	news,
}: IGetProfileNewsJsonLd) => ({
	'@context': 'https://schema.org',
	'@type': 'CollectionPage',
	name: title,
	description,
	hasPart: news.map(item => {
		const usageLocale = INT_LANGS?.find(lang => item?.['newsTitle']?.[lang]);

		const title =
			(item?.['newsTitle'] as LocalizedString)?.defaultValue ||
			(item?.['newsTitle'] as LocalizedString)?.[usageLocale];

		return {
			'@type': 'NewsArticle',
			headline: title,
			datePublished:
				!!item?.publicationTime &&
				dayjs(+item?.publicationTime * 1000).toISOString(),
			description: `${title} - ${item?.['newsAuthor']?.user?.name}`,
			url: item?.url,
			...addIfObj(!!item?.mainPhoto?.url, { image: item?.mainPhoto?.url }),
			...addIfObj(item?.commentsCount != 0, {
				commentCount: item?.commentsCount,
			}),
			about: {
				'@type': 'Thing',
				name: item?.mainSport?.name?.defaultValue,
			},
			inLanguage: item?.languages?.[0],
		};
	}),
});

interface IPostArticle {
	locale?: string;
	post?: Post & { images?: object[] };
	postHruOrId?: string;
	comments?: Comment[];
}

export const getPostArticleJsonLd = ({
	post,
	locale,
	postHruOrId,
	comments,
}: IPostArticle) => {
	const {
		commentsCount,
		author,
		title,
		createdTime,
		publicationTime,
		modifiedTime,
		mainSport,
		seo,
		mainPhoto,
		tags,
	} = post;

	const createdDate = dayjs(+createdTime * 1000).format();
	const publishedDate = dayjs(+publicationTime * 1000).format();
	const modifiedDate = dayjs(+modifiedTime * 1000).format();

	const postTitle = title?.[locale];
	const authorName = author?.[0]?.user?.name;
	const authorId = author?.[0]?.user?.id;
	const authorURL = `https://tribuna.com/${locale}/profile/${authorId}/`;
	const postURL = `https://tribuna.com/${locale}/blogs/${postHruOrId}/`;
	const postSeoTitle = seo?.title?.[locale] || postTitle;
	const postSeoDescription = seo?.description?.[locale];
	const mainPhotoUrl = mainPhoto?.url;

	const mainImage = {
		'@type': 'ImageObject',
		representativeOfPage: 'true',
		url: mainPhotoUrl,
		width: mainPhoto?.meta?.width,
		height: mainPhoto?.meta?.height,
	};

	const articleSection = mainSport?.name?.[locale];
	const postTags = tags?.map(tag => tag?.title?.[locale]).join(', ');
	const keywords = `${postTitle}, ${postTags}, ${articleSection}`;

	const mentions = tags?.map(tag => {
		const tagName = tag?.title?.[locale];

		const directory = getTagDirectoryByType(
			tag?.type,
			(tag?.extra as TagTeamExtra)?.type === TagTeamType.National,
		);

		const tagUrl = `${TRIBUNA_COM_URL}${locale}/${
			directory ? `${directory}/` : ''
		}${tag?.hru || tag?.id}/`;

		return {
			name: tagName,
			'@type': 'Thing',
			url: tagUrl,
		};
	});

	return {
		'@context': 'https://schema.org',
		'@type': 'Article',
		url: postURL,
		name: preventXss(postTitle),
		headline: preventXss(postSeoTitle),
		'@id': postURL,
		description: preventXss(postSeoDescription),
		articleBody: preventXss(postSeoDescription),
		inLanguage: locale?.toUpperCase(),
		dateCreated: createdDate,
		datePublished: publishedDate,
		dateModified: modifiedDate,
		keywords,
		thumbnailUrl: mainPhotoUrl,
		image: [mainImage],
		articleSection,
		mainEntityOfPage: {
			'@type': 'WebPage',
			'@id': postURL,
		},
		publisher: {
			'@type': 'Organization',
			name: 'Tribuna.com',
			logo: {
				'@type': 'ImageObject',
				url: 'https://static.tribuna.com/public/assets/approved-logos/championsleague/apple-icon-180x180.png',
				width: 512,
				height: 512,
			},
		},
		author: {
			'@type': 'Person',
			name: preventXss(authorName),
			url: authorURL,
		},
		about: mentions,
		mentions,
		commentCount: commentsCount,
		...addIfObj(!!comments?.length, {
			comment: comments?.map(comment => ({
				'@type': 'Comment',
				author: {
					'@type': 'Person',
					name: preventXss(comment?.user?.name),
					url: `https://tribuna.com/${locale}/profile/${comment?.user?.id}/`,
				},
				dateCreated: dayjs(+comment?.created * 1000).toISOString(),
				text: preventXss(comment?.text),
			})),
		}),
	};
};

interface IGetBlogsPageJsonLd {
	sport: Sport;
	posts: Post[];
	locale: string;
	title: string;
	description: string;
}

export const getBlogsPageJsonLd = ({
	sport,
	posts,
	locale,
	title,
	description,
}: IGetBlogsPageJsonLd) => {
	const isBetting = sport?.id === SportId.Bet;
	const isFootball = sport?.id === SportId.Football;
	const rootLink = isFootball
		? `${locale}/blogs/`
		: `${locale}/${
				isBetting ? BETTING_SPORT_URLS?.[locale] : sport?.path
			}/blogs/`;

	return {
		'@context': 'https://schema.org',
		'@type': 'Blog',
		name: title,
		description,
		blogPost: posts?.map(post => {
			const title = post?.title?.defaultValue;

			const displayAuthors = post?.displayAuthor;
			const authors = (
				displayAuthors?.[0]?.user?.id ? displayAuthors : post?.author
			)
				?.slice(0, 1)
				.map(author => author?.user);
			const author = authors?.[0];
			const datePublished = dayjs(+post?.publicationTime * 1000).format();
			const description = post?.seoNew?.description;

			return {
				'@type': 'BlogPosting',
				headline: title,
				author: {
					'@type': 'Person',
					name: author?.nickname,
					url: `${TRIBUNA_COM_URL}${locale}/profile/${author?.id}/`,
				},
				datePublished,
				description,
				url: `${TRIBUNA_COM_URL}${rootLink}${post?.hru || post?.id}/`,
				image: post?.mainPhoto?.url,
			};
		}),
	};
};

export const getPartnersPageJsonLd = ({
	locale,
	partnerBlocks,
}: {
	locale: string;
	partnerBlocks: PartnerBlock[];
}) => {
	const t = useTranslations('seo');
	const partnersList = partnerBlocks?.reduce((acc, block) => {
		acc.push(...block?.links);
		return acc;
	}, []);

	return {
		'@context': 'https://schema.org',
		'@type': 'WebPage',
		name: t('partners-page-title'),
		url: `https://${TRIBUNA_COM_URL}${locale}/partners/`,
		description: t('partners-page-description'),
		mainEntity: {
			'@type': 'ItemList',
			itemListElement: partnersList?.map((text, url) => ({
				'@type': 'Organization',
				name: text,
				url: url,
			})),
		},
	};
};
export const getPollJsonLd = ({
	locale,
	poll,
	sportName,
	rootLink,
}: {
	locale: LANG;
	poll: CustomPoll;
	sportName: string;
	rootLink: string;
}) => {
	const {
		publicationTime,
		modifiedTime,
		commentsCount,
		topLevelComments,
		tags,
		mainSport,
		title,
		hru,
		id,
	} = poll;

	const comments = topLevelComments?.items?.slice(0, 10);
	const pollTitle = title?.defaultValue || title?.[locale];
	const pollHruOrId = hru || id;

	const mentions = tags?.map(tag => {
		const tagName = tag?.title?.[locale];

		const directory = getTagDirectoryByType(
			tag?.type,
			(tag?.extra as TagTeamExtra)?.type === TagTeamType.National,
		);

		const tagUrl = `${TRIBUNA_COM_URL}${locale}/${
			directory ? `${directory}/` : ''
		}${tag?.hru || tag?.id}/`;

		return {
			'@type': 'Thing',
			name: tagName,
			url: tagUrl,
		};
	});

	const mainSportThing = {
		name: sportName,
		'@type': 'Thing',
		url:
			mainSport?.id === SportId.Football
				? `${TRIBUNA_COM_URL}${locale}/`
				: `${TRIBUNA_COM_URL}${locale}/${
						mainSport?.id === SportId.Bet
							? BETTING_SPORT_URLS?.[locale]
							: mainSport?.path
					}/`,
	};

	const pollSection = mainSport?.name?.defaultValue;
	const pollTags = tags?.map(tag => tag?.title?.[locale]).join(', ');
	const keywords = `${pollTags}, ${pollSection}`;

	const localizedStructuredDescription =
		poll?.structuredDescription?.defaultValue;
	const description = localizedStructuredDescription?.length
		? ((localizedStructuredDescription?.[0]?.value as any)?.elements?.[0]?.value
				?.text as string)
		: '';

	return {
		'@context': 'https://schema.org',
		'@type': 'Question',
		url: `${TRIBUNA_COM_URL}${rootLink}/${pollHruOrId}/`,
		name: pollTitle,
		text: description,
		dateCreated: new Date(+publicationTime * 1000)?.toISOString(),
		datePublished: new Date(+publicationTime * 1000)?.toISOString(),
		dateModified: new Date(+modifiedTime * 1000)?.toISOString(),
		author: {
			'@type': 'Organization',
			name: 'Tribuna.com',
		},
		keywords,
		...addIfObj(!!mentions?.length, {
			mentions,
		}),
		about: [...mentions, mainSportThing],
		commentCount: commentsCount,

		...addIfObj(!!poll?.options?.length, {
			suggestedAnswer: poll?.options?.map(option => ({
				'@type': 'Answer',
				text:
					(option as CustomPollOption)?.optionText?.[locale] ||
					(option as TagPollOption)?.tag?.title?.[locale],
				upvoteCount: option?.votes,
			})),
		}),
		...addIfObj(!!comments?.length, {
			comment: comments?.map(comment => ({
				'@type': 'Comment',
				author: {
					'@type': 'Person',
					name: preventXss(comment?.user?.name),
					url: `https://${TRIBUNA_COM_URL}${locale}/profile/${comment?.user?.id}/`,
					image: comment?.user?.avatar?.url,
				},
				dateCreated: dayjs(+comment?.created * 1000)?.toISOString(),
				text: preventXss(comment?.text),
				upvoteCount: comment?.reactions?.likeCount,
				downvoteCount: comment?.reactions?.dislikeCount,
			})),
		}),
	};
};
